import React from 'react';

function FullScreenIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_6632_11329)">
                <path d="M0.966371 0H6.7314C6.99419 0 7.24618 0.104459 7.432 0.290381C7.61781 0.476304 7.72221 0.728478 7.72221 0.991412C7.72221 1.25435 7.61781 1.50652 7.432 1.69244C7.24618 1.87837 6.99419 1.98282 6.7314 1.98282H3.34653L6.84372 5.46961C7.02897 5.65334 7.13367 5.90317 7.13483 6.16416C7.13598 6.42514 7.03349 6.67588 6.84987 6.86124C6.66624 7.0466 6.41655 7.15141 6.15571 7.15256C5.89487 7.15372 5.64427 7.05112 5.45901 6.8674L1.94953 3.38061V6.73656C1.95097 6.86715 1.92632 6.99672 1.87703 7.11766C1.82775 7.23859 1.7548 7.34844 1.6625 7.44078C1.57021 7.53313 1.46042 7.60612 1.33956 7.65543C1.21869 7.70475 1.08919 7.72941 0.95867 7.72797C0.829746 7.7278 0.702184 7.70187 0.583419 7.65168C0.464654 7.60148 0.357103 7.52802 0.267094 7.43566C0.177084 7.34331 0.10639 7.23391 0.0592308 7.11385C0.0120712 6.9938 -0.0106116 6.86552 -0.00753697 6.73656V0.991412C-0.00960323 0.732098 0.0907481 0.482478 0.271695 0.296818C0.452642 0.111157 0.699531 0.00445585 0.95867 0H0.966371Z" fill="black"/>
                <path d="M20.9965 22.0015H15.2545C15.1256 22.0046 14.9974 21.9818 14.8774 21.9347C14.7574 21.8875 14.6481 21.8168 14.5558 21.7267C14.4635 21.6367 14.3901 21.5291 14.3399 21.4102C14.2898 21.2914 14.2638 21.1638 14.2637 21.0348C14.2633 20.9045 14.2886 20.7754 14.3383 20.6549C14.3879 20.5344 14.4609 20.425 14.553 20.3328C14.645 20.2407 14.7545 20.1677 14.8749 20.118C14.9953 20.0683 15.1243 20.0429 15.2545 20.0434H18.6194L15.1222 16.5319C14.9443 16.3476 14.8449 16.1015 14.8449 15.8453C14.8449 15.5892 14.9443 15.343 15.1222 15.1587C15.2129 15.0653 15.3216 14.991 15.4415 14.9402C15.5615 14.8895 15.6904 14.8633 15.8207 14.8633C15.951 14.8633 16.0799 14.8895 16.1999 14.9402C16.3199 14.991 16.4285 15.0653 16.5192 15.1587L20.0041 18.6456V15.2588C20.0041 14.9934 20.1095 14.7389 20.297 14.5512C20.4846 14.3636 20.7389 14.2582 21.0041 14.2582C21.2694 14.2582 21.5237 14.3636 21.7113 14.5512C21.8988 14.7389 22.0042 14.9934 22.0042 15.2588V21.0363C21.997 21.2968 21.8878 21.5441 21.7002 21.7249C21.5126 21.9057 21.2615 22.0056 21.0011 22.0031L20.9965 22.0015Z" fill="black"/>
                <path d="M22.0048 0.991412V6.73656C22.0048 7.00194 21.8994 7.25646 21.7119 7.44412C21.5243 7.63177 21.2699 7.73718 21.0047 7.73718C20.7395 7.73718 20.4851 7.63177 20.2976 7.44412C20.11 7.25646 20.0046 7.00194 20.0046 6.73656V3.38061L16.5198 6.8674C16.3338 7.05128 16.0828 7.15444 15.8213 7.15444C15.5598 7.15444 15.3088 7.05128 15.1227 6.8674C14.9443 6.67848 14.8449 6.42845 14.8449 6.16853C14.8449 5.90861 14.9443 5.65852 15.1227 5.46961L18.6199 1.98282H15.2536C14.9908 1.98282 14.7387 1.87837 14.5529 1.69244C14.3671 1.50652 14.2627 1.25435 14.2627 0.991412C14.2627 0.728478 14.3671 0.476304 14.5529 0.290381C14.7387 0.104459 14.9908 0 15.2536 0H20.9955C21.2585 0.00311262 21.51 0.108195 21.6971 0.293106C21.8842 0.478018 21.9923 0.728355 21.9986 0.991412H22.0048Z" fill="black"/>
                <path d="M4.93913e-06 21.0363V15.2681C-0.000401464 15.141 0.0242756 15.0151 0.0726888 14.8976C0.121102 14.7801 0.192298 14.6734 0.282101 14.5835C0.371904 14.4937 0.478574 14.4225 0.595985 14.374C0.713395 14.3256 0.839212 14.3009 0.966212 14.3013C1.09483 14.2992 1.22255 14.3227 1.34207 14.3702C1.4616 14.4178 1.57058 14.4885 1.66267 14.5784C1.75476 14.6683 1.82818 14.7755 1.8787 14.8938C1.92923 15.0122 1.95587 15.1394 1.95707 15.2681V18.6548L5.46651 15.168C5.55599 15.0745 5.66347 15.0001 5.78246 14.9492C5.90145 14.8984 6.0295 14.8722 6.15888 14.8722C6.28827 14.8722 6.41632 14.8984 6.53531 14.9492C6.6543 15.0001 6.76178 15.0745 6.85126 15.168C6.94457 15.2563 7.01885 15.3628 7.06964 15.4808C7.12043 15.5989 7.14664 15.726 7.14664 15.8546C7.14664 15.9831 7.12043 16.1103 7.06964 16.2283C7.01885 16.3464 6.94457 16.4528 6.85126 16.5412L3.35407 20.0526H6.7389C6.99804 20.0571 7.24497 20.1637 7.42592 20.3494C7.60687 20.535 7.70722 20.7847 7.70515 21.044C7.70556 21.1711 7.68084 21.2969 7.63242 21.4144C7.58401 21.5319 7.51286 21.6387 7.42306 21.7285C7.33325 21.8184 7.22658 21.8896 7.10917 21.938C6.99176 21.9864 6.8659 22.0112 6.7389 22.0108H0.973912C0.846855 22.0114 0.720907 21.9868 0.603403 21.9384C0.4859 21.8901 0.379176 21.8188 0.289332 21.7289C0.199488 21.6391 0.128307 21.5323 0.0799666 21.4147C0.0316259 21.2971 0.00704699 21.1711 0.00765834 21.044L4.93913e-06 21.0363Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_6632_11329">
                    <rect width="22" height="22" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default FullScreenIcon;
