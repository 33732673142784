import {APPLICATION_LOGIN_PATH, USER_API_PATH} from '../../config/config.js';
import {initPhoneNumber} from '../../functions/intrernationalPhoneNumber';
import s3Upload from '../../functions/s3Upload';
import request from '../../library/request';
import store from '../../store';
import setGlobalState from '../setGlobalState';

const getLang =() =>({
    lang:store.getState().user.lang,
    language:store.getState().user.lang
});
const getLangloc=() =>({
    ...(store.getState().user.langloc?{
        langloc: store.getState().user.langloc,
        locale: store.getState().user.langloc.split('_')[1]
    }:{}),
});

const applicationLoginParams = () => store.getState().auth.isApplication ? {loginSource: 'application'} : {};

export const setIsLoginViaApp = (value) => ({
    type: 'SET_IS_LOGIN_VIA_APP',
    payload: value,
});

export const requestUser = async (action, method, path, args, userPath = null) => {
    // let res;
    // if(IS_PRODUCTION){
    //     res = await request(action, method, path, args);
    // }
    // else {
    const csrfToken = store.getState().auth.csrfToken;
    const headers = {'X-CSRF-Token': csrfToken};
    const res = await request(action, method, `${USER_API_PATH}${userPath || path}`, {...args}, {headers, withCredentials: true});
    // }
    return res;
};

export const userPackage = async (method, user) => {
    const packageData = await request('user.userPackage', method, '/user/package');
    if(packageData.pid)
        user.defaultPid = packageData.pid;
    if(packageData.userInfo)
        user = {...user, ...packageData.userInfo};
    return user;
};

export const isNoPackageOrEvent = (res,email)=>{
    if(res?.noPackageOrEvent){
        setGlobalState('ui.login.noPackageOrEvent',true);
        setGlobalState('ui.login.existingUserEmail',email);
        return true;
    }
    return false;
};

export const isInactiveUser = (res)=>{
    if(res?.isInactiveUserEvent || res?.isInactiveUserPackage){
        setGlobalState('ui.login.isInactiveUser',true);
        return;
    }
    return false;
};
const userSuccess = (res) => {
    let user = res.user ? res.user : [];
    if(res.exp) {
        localStorage.setItem('exp', JSON.stringify(res.exp));
        setGlobalState('auth.exp', res.exp);
    }
    if(res.csrfToken){
        localStorage.setItem('csrfToken', JSON.stringify(res.csrfToken));
        setGlobalState('auth.csrfToken:', res.csrfToken);
    }
    setGlobalState('auth.message', '');
    user.isMaster = user.isMaster || false;
    user.nameFirst = user?.firstName;
    user.nameLast = user?.lastName;
    user.lang = user?.language;
    user.picture = user?.profileImage;
    user.langloc= `${user?.lang}_${user?.locale}`;
    user.ownerEmailVerified = user.emailVerified;
    localStorage.setItem('user', JSON.stringify(user));
    setGlobalState('user', user);
    setGlobalState('auth.isLoggedIn',true);

    if(store.getState().auth.isApplication && res.applicationToken){
        if(res.applicationExp) {
            localStorage.setItem('applicationExp', JSON.stringify(res.exp));
            setGlobalState('auth.applicationExp', res.exp);

        }
        window.location.href = `${APPLICATION_LOGIN_PATH}?applicationToken=${res.applicationToken}&csrf=${res.csrfToken}&user=${JSON.stringify(user)}`;
    }

};
export const loginUser = async (user,isLoginByPassword) => {
    try {
        const res = await requestUser('user.loginUser', 'post', '/login/', {...user,isLoginByPassword, ...applicationLoginParams()});
        if(res.user){ // && !IS_PRODUCTION
            res.user = await userPackage('get', res.user);
        }
        if(typeof Android != 'undefined') {
            // eslint-disable-next-line no-undef
            Android.loginInfo(JSON.stringify(res.user));
        }
        if(isNoPackageOrEvent(res,user.email))
            return ;
        if(isInactiveUser(res))
            return true;
        if(res.isCodeSend)
            return {isCodeSend:true};
        userSuccess(res);
    } catch (error) {
        if(error!== 'user.loginUser is blocked')
            setGlobalState('ui.codeInput.isWrong',true);
    }
};
export const loginByEmail = async (email,sendToEmail) => {
    const sendTo=sendToEmail?'email':'phone';
    const res = await requestUser('user.loginByEmail', 'post', '/loginEmail', {email,sendTo});
    isNoPackageOrEvent(res,email);
    if(isInactiveUser(res))
        return true;
    if(res[sendTo])
        setGlobalState(`user.${sendTo}`,res[sendTo]);
};

export const getTokenToSignUp = async (user) => {
    const phone = initPhoneNumber({nationalNumber: user.phone, country: user.country}).internationalNumber;
    const res = await requestUser('user.getTokenToSignUp', 'post', '/signUpEmail/', {...user,...getLang(),phone});
    if(res.isExistingUser)
        return res;
    setGlobalState('auth.token', res.token);
};

export const signUp = async (user,code=null,packageType='') => {
    try {
        const phone = initPhoneNumber({nationalNumber: user.phone, country: user.country}).internationalNumber;
        const firstName = user.nameFirst;
        const lastName = user.nameLast;
        const res = await requestUser('user.signUp', 'post', '/signUp/', {...user,phone,code,packageType,token:store.getState().auth.token,...getLang(),...getLangloc(), firstName, lastName});
        if(res.user){ // && !IS_PRODUCTION
            res.user = await userPackage('post', res.user);
            res.pid = res.user.defaultPid;
        }
        if(res?.user){
            userSuccess(res);
        }
        return res;
    } catch (error) {
        setGlobalState('ui.codeInput.isWrong',true);
    }
};

export const signUpGoogle = async (user) => {
    const res = await requestUser('user.signUpGoogle', 'post', '/signUpGl/', {...user,...getLang(),...getLangloc()}, '/signUpGoogle');
    isNoPackageOrEvent(res,user.email);
    if(isInactiveUser(res))
        return true;
    if(res.user){
        const user = {
            nameFirst: res.user?.firstName,
            nameLast: res.user?.lastName,
            ...res.user
        };
        return user;
    }
};

export const loginUserGL = async (user) => {
    const res = await requestUser('user.loginUser', 'post', '/loginGl/', {...user, ...applicationLoginParams()}, '/loginGoogle');
    if(res.user){ // && !IS_PRODUCTION
        res.user = await userPackage('get', res.user);
    }
    userSuccess(res);
};
export const loginUserFB = async (user) => {
    const res = await request('user.loginUser', 'post', '/loginFb/', {...user, ...applicationLoginParams()});
    userSuccess(res);
};

export const loginApple = async (user) => {
    const res = await requestUser('user.loginUser', 'post', '/loginApple', {...user, ...applicationLoginParams()});
    if(res.user){ // && !IS_PRODUCTION
        res.user = await userPackage('get', res.user);
    }
    userSuccess(res);
};

export const signUpApple = async (user) => {
    const res = await requestUser('user.signUpApple', 'post', '/signUpApple', {...user,...getLang(),...getLangloc()});
    isNoPackageOrEvent(res,user.email);
    if(isInactiveUser(res))
        return true;
    if(res.user){
        const user = {
            nameFirst: res.user?.firstName,
            nameLast: res.user?.lastName,
            ...res.user
        };
        return user;
    }
};
export const resetPassword = async (email, password, password2, token) => {
    const res = await requestUser('user.resetPassword', 'post', '/resetPassword/', {email, password, password2, token});
    if(res.isCodeSend) {
        if(res.phone)
            setGlobalState('user.phone',res.phone);
        else
            setGlobalState('user.email',res.email);
        return {isCodeSend:true};
    }
};
export const updatePassword = async (email, password, password2, token, code) => {
    const res = await requestUser('user.updatePassword', 'post', '/updatePassword', {email, password, password2, token, code});
    if(res.response==='OK'){
        return true;
    }
};
export const forgetPassword = async (email) => {
    await requestUser('user.forgetPassword', 'post', '/forgetPassword', {email});
};
export const logout = async () => {
    const socket = store.getState().auth?.socket;
    request('auth.deleteSocket', 'delete', '/user/deleteSocket/', {socket}).then();
    await requestUser('user.logout', 'post', '/logout/', {socket});

};

export const updateUserDetails = async (user) => {
    if(user.picture && user.picture!=='default' ){user.picture = await uploadFile(user.picture, '', 'profile', user.uid);}
    user.profileImage = user.picture;
    const res = await requestUser('user.updateUserDetails', 'put', '/user/', user, '/update');
    if(res.user) {
        userSuccess(res);
    }
};
export const updateUserEmail = async (user) => {
    const res = await request('user.updateUserEmail', 'put', '/userEmail/', user);
    if(res){
        setGlobalState('account.ownerEmail', res.packageOwnerEmail);
        if(res.userEmail){
            let loggedUser = JSON.parse(localStorage.getItem('user'));
            loggedUser.email = res.userEmail;
            localStorage.setItem('user', JSON.stringify(loggedUser));
            setGlobalState('user.email', res.userEmail);
        }

    }
};
export const updateLocalStorage=(item,fields)=>{
    let localStorageItem = JSON.parse(localStorage.getItem(item));
    localStorageItem={...localStorageItem,...fields};
    localStorage.setItem(item, JSON.stringify(localStorageItem));
};

export const updateUserInfo=async(values)=>{
    values = await uploadUserImages(values);
    const res=await request('user.updateUserInfo', 'put', '/user/info', values);
    setGlobalState('user.info',{...store.getState().user.info,...res});
};

export const uploadUserImages = async (values)=>{
    if(values.gallery?.length) {
        values.gallery = await Promise.all(values.gallery?.map(async (image, index) =>  await uploadFile(image, '', `userGallery${index}`)));
    }
    if(values.certificate.base64) {
        values.certificate.base64 = await uploadFile(values.certificate.base64, '', 'userCertificate');
    }
    if(values.picture) {
        values.picture = await uploadFile(values.picture, '', 'teamImage');
    }
    return values;
};

export const getUserInfo=async()=>{
    const res=await request('user.getUserInfo', 'get', '/user/info');
    setGlobalState('user.info',res);
};

export const getIsExistingUser = async (user) => {
    const res = await requestUser('user.getIsExistingUser', 'post', '/checkExistingUser/', user);
    if(res.isExistingUser)
        return res;
    setGlobalState('auth.token', res.token);
};

export const getPreSignedUrl = async (mimeType  = 'image/jpeg', uploadType, id)=>{
    const res = await request('design.getPreSignedUrl'+uploadType, 'get', '/user/preSignedUrl', {mimeType, uploadType, id});
    return res;
};

export const uploadFile = async (base64, mimeType , uploadType, id) => {
    try {
        if(base64.substring(0, 4) === 'http') {
            return base64;
        }
        mimeType = mimeType || base64.substring('data:'.length, base64.indexOf(';base64'));
        const urlObj = await getPreSignedUrl(mimeType , uploadType, id);
        if(urlObj?.url){
            const res = await s3Upload(base64, mimeType , urlObj.url, urlObj.key);
            return res;
        } else {
            return null;
        }
    } catch (error){
        return null;
    }
};
