import React, {useEffect, useRef} from 'react';
import * as css from './SupportComponent.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Btn from '../../component/button/btn/Btn';
import useOnClickOutside from '../../hooks/UseOnClickOutside';
import SupportCloseButton from './SupportCloseButton';
import useShow from '../../hooks/useShow';

function SupportComponent() {
    const {t} = useTranslation('layout');
    const glassixWidgetRef = useRef(null);
    const isInlineMode = useSelector(s => s.ui.isInlineMode);
    const {isOpen, open, close} = useShow();

    const checkWidgetOpen = () => {
        const widgetContainer = document.getElementById('glassix-widget-iframe-container');
        return widgetContainer && widgetContainer.classList.contains('open');
    };

    const openWidget=(e)=>{
        open();
        // alert('ישנה תקלה בחברת גלסיקס הנותנת לנו את ממשק שירות הלקוחות \n' +
        //     'לפניות בתמיכה עד תיקון התקלה \n' +
        //     'ניתן להתקשר או לשלוח ווטסאפ למספר 0508462478');
        // return;
        e.stopPropagation();
        if(!checkWidgetOpen()){
            const widgetLauncher = document.getElementById('glassix-widget-launcher');
            widgetLauncher.click();
        }
    };
    const closeWidget=(e)=>{
        e.stopPropagation();
        if(checkWidgetOpen()){
            close();
            const widgetLauncher = document.getElementById('glassix-widget-launcher');
            widgetLauncher.click();
        }
    };

    useEffect(() => {
        // Function to check and set the ref to the Glassix widget
        const checkAndSetWidgetRef = () => {
            const widgetElement = document.getElementById('glassix-widget');
            if(widgetElement) {
                glassixWidgetRef.current = widgetElement;
            }
        };

        // Check immediately if the widget is already loaded
        checkAndSetWidgetRef();

        // If the widget loads later, use a MutationObserver to detect changes and set the ref
        const observer = new MutationObserver(checkAndSetWidgetRef);
        observer.observe(document.body, {childList: true, subtree: true});

        // Clean up the observer when the component unmounts
        return () => observer.disconnect();
    }, []);

    // Use the hook with the ref and the handler
    useOnClickOutside(glassixWidgetRef, closeWidget);

    const userMessageShow = useSelector((state) => state.ui.userMessage.text);

    const isOpenEvents=useSelector(s=>s.ui.show.isOpenEvents);

    return (<>
        <css.container $isInlineMode={isInlineMode} onClick={openWidget} className={`${userMessageShow ? 'show':''} ${isOpenEvents?' margin':''} ${checkWidgetOpen() && 'open'}`}>
            <Btn className={(`${checkWidgetOpen() ? ' open' : ' handIcon' }`)}>
                <css.handIcon className={`${checkWidgetOpen() ? 'open' : 'handIcon' }`}/>
                <css.SupportTitle className={(`${checkWidgetOpen() ? 'open' : ''}`)}>{t('support')}</css.SupportTitle>
            </Btn>
        </css.container>
        <SupportCloseButton isOpen={isOpen} onClick={closeWidget}/>
    </>);
}

export default SupportComponent;
