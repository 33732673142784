import * as css from './Btn.css';
import ConditionalWrapper from '../../conditionalWrapper/ConditionalWrapper';
import {XHR_LOADING_STATE} from '../../../assets/constants/constants';
function Btn({style='',className,children, onClick,disabled,href=null}) {

    return (
        <ConditionalWrapper condition={!!href} wrapper={(children)=><a href={href}>{children}</a>} data-cy={'btnHref'}>
            <css.btn
                $btnCss={style}
                id="classicButton"
                data-cy='classicButton'
                disabled={className === XHR_LOADING_STATE}
                className={`${className} ${disabled && ' disabled'}`}
                {...((disabled || href) ? {} : {onClick})}>
                {className !== XHR_LOADING_STATE && children}
            </css.btn>
        </ConditionalWrapper>

    );
}

export default Btn;
