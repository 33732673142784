import styled from 'styled-components';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {update} from '../../../../assets/symbols/symbols/Symbols.css';
import {getColor} from '../../eventRealtime/EventRealtime.css';

export const lastUpdate = styled.div`
    position: relative;
    inset-inline-start: calc(10% + 30px);
    inset-block-start: 40px;
    color: ${p=>getColor(p.theme.isDarkMode)};
    width: fit-content;
    &::before{
        display: block;
        content: '';
        height: 20px;
        width: 20px;
        background-color: ${p=>getColor(p.theme.isDarkMode)};
        -webkit-mask-image: url("${update}");
        mask-image: url("${update}");
        -webkit-mask-position:center center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 20px;
        position: relative;
        inset-block-start: 20px;
        inset-inline-start: -25px;
    }
    ${mobileMedia}{
        margin-bottom: 20px;
        inset-block-start: 0;
        inset-inline-start: calc(2% + 30px);
    }
`;
