import * as css from './TicketsSection.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {getEventDay} from '../../../../../../redux/realtime/realtimeActions';
import {TicketValidations} from '../ticketValidations/TicketValidations';

export const TicketsSection = () => {
    const {t} = useTranslation('realtime');
    const event = useSelector((s:any) => s.event);
    const tickets = useSelector((s:any) => s.realtime?.eventDay || []);
    
    useEffect(() => {
        if(event?.eid && !tickets?.length) {
            getEventDay(event.eid);
        }
    }, []);

    return (
        <css.wrapper>
            <css.title>{t('ticket')}</css.title>
            <css.list>
                {tickets.map((ticket:any) => (
                    <TicketValidations key={ticket.tid} ticket={ticket} />
                ))}
            </css.list>
        </css.wrapper>
    );
};
