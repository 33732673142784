import * as css from './ExplainMap.css';
import FormikDropzon from '../../../../component/dropzone/FormikDropzone';
import Btn from '../../../../component/button/btn/Btn';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {sendMap} from '../../../../redux/event/eventActions';
import {useSelector} from 'react-redux';
import TextareaInput from '../../../../component/input/textareaInput/TextareaInput';
import useStep from '../StepLeft/useStep';
import {useContext} from 'react';
import {newEventContext} from '../newEventForm/NewEventForm';
import {uploadFile} from '../../../../redux/user/userActions';

function ExplainMap({close,title, action}) {
    const {t} = useTranslation('newEvent');
    const eid = useSelector(s=>s.event.eid);
    const [{value}]=useField('map');
    const {steps,currentStep}=useContext(newEventContext);
    const {onContinue} = useStep(steps[currentStep]);
    const onClick = async() => {
        const imageLink = await uploadFile(value?.sendMap?.base64, '', 'uploadMap', eid);
        const message = t('sendMapMassage',{map:imageLink,eid:eid,text:value.message});

        await sendMap({message: action==='mapError' ? message + t('errorMap',{eid:value.eid}) : message,comment:t('comment',{comment:t(`${action}`)})})
            .then(()=>{
                onContinue();
            });
    };
    const back = () => {
        close();
    };
    return (<>
        <css.back onClick={back}/>
        <css.wrap>
            <css.title>{title}</css.title> 
            <TextareaInput name={'map.message'}/>
        </css.wrap>
        <css.explainButtons $isUpload={value.sendMap}>
            <FormikDropzon name={'map.sendMap'} text={value?.sendMap?.name || t('uploadMap')} fileTypes={'image/jpeg, image/jpg, application/pdf'} />
            <Btn className={'white'} disabled={value.message ==='' && !value.sendMap} onClick={onClick}>{t('send')}</Btn>
        </css.explainButtons>
    </>
    );
}

export default ExplainMap;
