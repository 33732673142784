import styled from 'styled-components';
import {BGImageCenter, GRAY2, mobileMedia, VERY_PALE_BLUE} from '../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../fonts';

export const wrapper = styled.div`
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 400px;
    ${mobileMedia}{
        height: 100%;
    }
`;

export const header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const title = styled.div`
    ${realtimeFonts.windowTitle}
`;

export const buttons = styled.div`
    display: flex;
    gap: 10px;
`;

export const button = styled.div<{ image?: string}>`
    position: relative;
    inset-block-start: 5px;
    height: 20px;
    width: 20px;
    ${p=>BGImageCenter(p.image, '20px 30px')};
    cursor: pointer;
    &.disabled{
        opacity: 0.3;
        cursor: default;
    }
`;
export const icon = styled.div<{image?: string}>`
    height: 20px;
    width: 20px;
    ${p=>BGImageCenter(p.image, '15px 15px')}
    margin-inline-end: 5px;
`;

export const content = styled.div`
    display: grid;
    grid-template-areas: 
    "autoUpdate update ."
    "chart terminals tickets";
    grid-template-columns: 5fr 2fr 3fr;
    grid-template-rows: 1fr 8fr;
    gap: 10px;
    height: 300px;
    
    ${mobileMedia} {
        grid-template-areas: 
        "autoUpdate"
        "chart"
        "update"
        "terminals"
        "tickets";
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: 100%;
    }
`;
export const update = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    grid-area: update;
    width: max-content;
    height: 30px;
    padding: 4px 10px 4px 12px;
    border-radius: 16px;
    background: ${VERY_PALE_BLUE};
    color: ${GRAY2};
    text-align: center;
    ${realtimeFonts.text}
`;
export const autoUpdate = styled(update)`
    grid-area: autoUpdate;
`;

export const link = styled.span`
    cursor: pointer;
    text-decoration: underline;
`;

export const section = `
    background: ${VERY_PALE_BLUE};
    border-radius: 10px;
    ${mobileMedia}{
        height: 200px;
    }
`;
export const validationsChartSection = styled.div`
    grid-area: chart;
    ${section}
    ${mobileMedia}{
        height: 400px;
    }
`;
export const terminals = styled.div`
    grid-area: terminals;
    ${section}
`;
