import WindowTemplate from '../WindowTemplate/windowTemplate';

const grid = `
    "autoUpdate update ."
    "chart terminals tickets"
`;

const gridMoreStyle = {
    'grid-template-columns': '5fr 2fr 3fr',
    'gap': '10px',
    'justify-items': 'start'
};

const settings = [
    // Auto section
    {
        gridArea: 'autoUpdate',
        width: '30%',
        height: '30px',
    },        
    // Update section
    {
        gridArea: 'update',
        width: '100%',
        height: '30px'
    },
    // Chart section
    {
        gridArea: 'chart',
        width: '100%',
        height: '300px'
    },
    // Terminals section
    {
        gridArea: 'terminals',
        width: '100%',
        height: '300px'
    },
    // Tickets section
    {
        gridArea: 'tickets',
        width: '100%',
        height: '300px'
    }
];

function EventDayTemplate() {
    return (
        <WindowTemplate 
            gridMoreStyle={gridMoreStyle} 
            gridAreas={grid} 
            settings={settings}
        />
    );
}

export default EventDayTemplate;