import {useEffect, useMemo} from 'react';
import * as css from './EventRealtime.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Window from '../components/window/window';
import Transactions from '../components/windows/transactions/transactions';
import {Cash} from '../components/cash/Cash';
import {CashQuestion} from '../components/cashQuestion/CashQuestion';
import {CashExplain} from '../components/cashExplain/CashExplain';
import {TType, eventTypes} from './eventTypes';
import useShow from '../../../hooks/useShow';
import FormButton from '../../../layout/eventMenu/formButtton/FormButtton';
import {getImageColors, postEventReport} from '../../../redux/event/eventActions';
import DetailItem from '../components/detailItem/DetailItem';
import UserProfileMenu from '../../../layout/topMenu/layoutUserProfile/layoutUserProfileMenu/LayoutUserProfileMenu';
import {ThemeProvider} from 'styled-components';
import {getRealtime, getRealtimeDesign, getWeather, updateRealtimeDesign} from '../../../redux/realtime/realtimeActions';
import Tooltip from '../../../component/tooltip/Tooltip';
import useMobile from '../../../hooks/useMobile';
import SimpleExplanationWindow from '../components/simpleExplanationWindow/simpleExplanationWindow';
import {IS_PRODUCTION, LIVE_DOMAIN, TICK_DOMAIN} from '../../../config/config';
import SoldTickets from '../components/windows/soldTickets/soldTickets';
import RealtimeData from '../components/windows/realtimeData/RealtimeData';
import setGlobalState from '../../../redux/setGlobalState';
import {setCurrentRating} from '../../components/rating/Rating';
import useRealtimeGrid from '../useRealtimeGrid';
import GridDrag from '../../../component/gridDrag/GridDrag';
import Weather from '../components/windows/weather/weather';
import PopUpBubble from '../../../component/popUpBubble/PopUpBubble';
import {TURQUISE_BLUE, WHITE} from '../../../globalVariables/GlobalVariables';
import ButtonIcon from '../../../component/button/buttonIcon/buttonIcon';
import {getDateFormat} from '../../../library/dates';
import SystemMessageSwiper from '../components/windows/systemMessage/systemMessageSwiper';
import useTimeout from '../../../hooks/useTimeout';
import EventRolePermission from '../../components/rolePermission/EventRolePermission';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import {Profile} from '../components/profile/Profile';
import ManagePromotion from '../../../component/managePromotion/ManagePromotion';
import AccountPermission from '../../components/accountPermission/AccountPermission';
import PublishPageBtn from '../../components/publishPageBtn/PublishPageBtn';
import EventActive from '../../components/eventActive/EventActive';
import useSocket from '../../../hooks/useSocket';
import QrCodePopup from '../../../layout/eventMenu/formButtton/QrCodePopup/QrCodePopup';
import getEventUrl from '../../../functions/getEventUrl';
import QRCode from '../../../assets/icons/QRCode';
import RealtimeHeader from '../realtimeHeader/RealtimeHeader';
import TicketsTicket from '../components/windows/tickets/TicketsTicket/TicketsTicket';
import TicketsGivechak from '../components/windows/tickets/TicketsGivechak/TicketsGivechak';
import LastUpdateTimer from '../components/lastUpdateTimer/LastUpdateTimer';
import {EventDay} from '../components/eventDay/EventDay';
import useQueryStrings from '../../../hooks/useQueryStrings';
import {onConfirmPortalRequest} from '../../eventSettings/mailingForm/MailingForm';
// import {getTs, subtractTs} from '../../../library/dates';

export const eventStatusComp={hub:PublishPageBtn, default:EventActive};
const QR_REF = '2t24';

function EventRealtime (){
    const event = useSelector((s:any) => s.event);
    const user = useSelector((s:any) => s.user);
    const {tickets} = useSelector((s:any) => s.tickets);
    const realtimeDesign = useSelector((s:any) => s.realtime.user.realtimeDesign);
    const [weather, weatherXHR] = useSelector((s:any) => [s.realtime.event.weather, s.realtime.event.weatherXHR]);
    const showMessage = useSelector((s:any) => (!!s.realtime.systemMessage?.windowMessages?.length || !s.realtime.systemMessageXHR) && s.realtime.systemMessage.windowMessages?.length);
    const realtimeMessage = useSelector((s:any) => s.realtime.systemMessage?.windowMessages);
    const {t} = useTranslation('realtime');
    const {open, close, isOpen} = useShow();
    const {isOpen: isBubbleOpen,open: openBubble,close:closeBubble,showClass:bubbleShowClass}=useShow();
    const isMobile = useMobile();
    const {windowsList, reorderGrid, showHideWin} = useRealtimeGrid(newGrid=>updateRealtimeDesign('gridAreas', newGrid));
    const isMasterUser = useSelector((s: any)=>s.user.isMaster);
    const showReport = parseInt(getDateFormat(event.timeEnd, 'YYYY', event.timeZone)) >= 2024;
    const timerRating=useTimeout();
    const QRShow = useShow();

    useQueryStrings('portalRequest',(portalType)=>onConfirmPortalRequest(portalType));

    const getUrl =()=>{
        const currentDomain = event.type==='hub'? LIVE_DOMAIN : TICK_DOMAIN;
        const urlPath =  getEventUrl(event.domain, event.landingPageUrl||event.name, event.type, currentDomain);
        return `${urlPath}?ref=${QR_REF}`;
    };
    useEffect(() => {
        getRealtimeDesign().then();
        if(!event.getImageColorsXHR)
            getImageColors().then();
        getRealtime().then();
        if(!weather && !weatherXHR) getWeather().then();
        timerRating.current = setTimeout(() => {
            setCurrentRating('newRealtime');
        }, 20000)as unknown as any;
    },[]);

    // const showEventDay = useMemo(() => {
    //     const weekBeforeTs = subtractTs(event.timeStart, 7, 'day');
    //     const nowTs = getTs();
    //     return nowTs >= weekBeforeTs && (event.type === 'ticket' || event.type === 'area');
    // }, [event.timeStart]);
    const showEventDay = false;

    const components = useMemo(()=> ({eventDay: {comp:  <EventDay />,
        draggable: false,
        show: showEventDay,
        deployable: false,
        height: 'max-content'
    },
    cash: {comp: <Cash />,
        explainComp: <CashQuestion />, 
        howArrivedComp:<CashExplain />,
        howArrivedTitle: t('howArrivedButton'),
        draggable: false,
        show: true,
        deployable: false
    },
    soldTickets: {comp: <SoldTickets />,
        explainComp: <SimpleExplanationWindow text={t('soldTicketsExplanation')} />,
        draggable: false,
        show: true,
        deployable: false
    },
    transactions: {comp: <Transactions />,
        explainComp: <SimpleExplanationWindow text={t('transactionsExplanation')} />,
        draggable: false,
        show: true,
        deployable: false
    },
    realtimeData: {comp: <RealtimeData />,
        explainComp: <SimpleExplanationWindow text={t('realtimeDataExplanation')} />,
        draggable: false,
        show: true,
        deployable: false,
        height: 'max-content'
    },
    tickets: {comp: (tickets?.length) ? (event.type === 'givechak' ? <TicketsGivechak/> : <TicketsTicket />):null,
        explainComp: <SimpleExplanationWindow text={t('ticketsExplanation')} />,
        draggable: false,
        show: !!tickets?.length,
        deployable: true,
        height: isMobile?'max-content':'390px'
    },
    weather: {comp: weather ? <Weather />:null,
        explainComp: <SimpleExplanationWindow text={t('weatherExplanation')} />,
        deployable: true,
        draggable: false,
        show: !!weather && isMasterUser,
        role: {rolePermission: 'access'},
        height: isMobile?'max-content':'100%'
    },
    message: {comp:  showMessage?<SystemMessageSwiper isPop={false} />:<></>,
        explainComp: null,
        deployable: true,
        draggable: false,
        show: showMessage,
        role: {rolePermission: realtimeMessage && (realtimeMessage[0]?.distributionRole || realtimeMessage[0]?.distribution_role) || 'access'},
        height: isMobile?'max-content':'390px'
    }
    }),[tickets, isMobile, weather, showMessage, showEventDay]);
    
    useEffectOnUpdate(()=>showHideWin('message', showMessage),[showMessage]);
    useEffectOnUpdate(()=>showHideWin('weather', !!weather),[weather]);
    useEffectOnUpdate(()=>showHideWin('eventDay', showEventDay),[showEventDay]);

    const getProps = item => {       
        return {children: components[item.gridArea]?.comp , gridArea: item.gridArea, role: {rolePermission: 'manage event'}, ...(components[item.gridArea] || {})};
    };
    const onDragEnd = (hi)=>{
        reorderGrid(hi?.map(item=>item.gridArea));
    };
    useSocket();
    
    const StatusComp = eventStatusComp[event?.type]||eventStatusComp.default;

    return (
        <ThemeProvider theme={{isDarkMode: realtimeDesign?.darkDisplay}}>
            <css.wrapper data-cy="realtime" colors={event.colors}>
                {!!isOpen && <UserProfileMenu close={close} closeMenu={close} hide={false} showEvents={()=>{}} userImgPath={user.picture} userName={`${user.nameFirst} ${ user.nameLast}`}/>}
                {!isMobile && <LastUpdateTimer />}
                <RealtimeHeader isEvent={true} />
                <css.gridTop>
                    <Profile openProfile={open}/>
                    <css.eventDetails data-cy="eventDetails">
                        <css.buttonsTop>
                            {!isMobile && <ButtonIcon BGColor={TURQUISE_BLUE} iconName={'cashRegister'} text={t('boxOffice')} onClick={() => setGlobalState('ui.newIframe.openBoxoffice', true)} />}
                            {(showReport && !isMobile) && <EventRolePermission rolePermission={'manage event'} views={[]} ><ButtonIcon iconName={'document'} text={t('report')} onClick={()=>postEventReport(event.eid)} onMouseLeave={closeBubble} onMouseOver={openBubble}/></EventRolePermission>}
                            {!!isMobile && <StatusComp />}
                            <css.wrapButton>
                                {isBubbleOpen&&<PopUpBubble open={openBubble} showClass={bubbleShowClass} close={closeBubble} color={WHITE} isText={true} >
                                    <css.reportPop >{t('reportExplanation')}</css.reportPop>
                                </PopUpBubble>}
                            </css.wrapButton>
                        </css.buttonsTop>
                        <css.buttonsBottom>
                            {!isMobile && <StatusComp />}
                            <css.shareButtons>
                                {!isMobile && <Tooltip text={t('QRCode')} dataTitleIcon={undefined}>
                                    <css.QRCodeButton onClick={() => QRShow.open()}>
                                        <QRCode />
                                    </css.QRCodeButton>
                                </Tooltip>}
                                <FormButton domain={event.type==='hub'? LIVE_DOMAIN : TICK_DOMAIN}/>
                                {(QRShow.isOpen && !isMobile) && <QrCodePopup {...QRShow} url={getUrl()} />}
                            </css.shareButtons>
                        </css.buttonsBottom>
                        <css.type icon={eventTypes[event.type as TType]?.icon}>{t(eventTypes[event.type as TType]?.text)}</css.type>
                        <css.eventName>{event.title}</css.eventName>
                        <css.colors>{!!event.colors && event.colors.map((color:string, index) => <css.color key={index} color={color}/>)}</css.colors>
                        <css.details>
                            {eventTypes[event.type as TType].details.map((item, index)=> {
                                return <DetailItem key={index} title={item}/>;
                            })}
                        </css.details>
                    </css.eventDetails>
                </css.gridTop>
                {!!isMobile && <LastUpdateTimer />}
                <AccountPermission permission={'promotion message'} message={undefined}>
                    <ManagePromotion/>
                </AccountPermission>
                <css.gridContainer showWeather={!!weather} windowsList={windowsList} eventType={event.type} showEventDay={showEventDay}>
                    <GridDrag onDragEnd={onDragEnd} items={windowsList?.filter(win=>components[win]?.show).map(win => ({gridArea: win})) || []} getProps={getProps as any} Comp={Window} idName={'gridArea'} undraggableChildren={undefined} />
                </css.gridContainer>
                <css.oldSystem onClick={()=>{setGlobalState('realtime.user.oldSystem', true); setCurrentRating('newRealtime');}}>{t('oldSystemText')}</css.oldSystem>
            </css.wrapper>
        </ThemeProvider>
    );
}

export default EventRealtime;
