import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import enUs from '../config/dayjs/en-us.json';
import heIl from '../config/dayjs/he-il.json';
import store from '../store';
import {DEFAULT_TIME_ZONE} from '../globalVariables/locals';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const tsToDate = (ts, locale, timeZone, format) => {
    if(!ts) {
        return null;
    }
    const locales = {'en-us': enUs, 'he-il': heIl};
    locale = locale ? locale.replace('_', '-') : 'en';
    timeZone = timeZone ? timeZone : DEFAULT_TIME_ZONE;
    format = format ? format : 'DD.MM.YYYY';
    format = (locales[locale] && locales[locale][format]) ? locales[locale][format] : format;
    return dayjs.unix(ts).locale(locale).tz(timeZone).format(format);
};

export function getTimeArray(timestamp,tz) {
    return dayjs.unix(timestamp).tz(tz||getTimeZone()).format('HH:mm').split(':').map(Number);
}

export function setHours([hours, minutes],timestamp, timezone) {
    return dayjs().tz(timezone||getTimeZone()).hour(hours).minute(minutes).second(0).unix();
}

const timeToDate = (time = '10:10') => time[2] === ':' ? new Date(2000, 0, 0, time.substring(0, 2), time.substring(3, 5))
    : new Date(2000, 0, 0, time.substring(0, 1), time.substring(2, 4));

function getTime(seconds) {
    let secondSum = parseInt(seconds, 10);
    let minutes = Math.floor((secondSum) / 60);
    let updatedSeconds = secondSum -  (minutes * 60);
    if(minutes<0)
        return '00:00';
    if(minutes < 10) {minutes = '0'+minutes;}
    if(updatedSeconds < 10) {updatedSeconds = '0'+updatedSeconds;}
    return minutes+':'+updatedSeconds;
}
const getTimeZone=()=>store.getState().event.timeZone||DEFAULT_TIME_ZONE;

const timeToDateTs =(timeString,timeZone)=> {
    const currentTime = dayjs().tz(timeZone||getTimeZone());
    const [hours, minutes] = timeString.split(':');
    const dateWithTime = currentTime.set('hour', Number(hours))
        .set('minute', Number(minutes))
        .toDate();
    return dateWithTime;
};

const getTs = (date=null,timeZone) => {
    if(date)
        return dayjs(date).tz(timeZone||getTimeZone()).unix();
    return dayjs().tz(timeZone||getTimeZone()).unix();
};
const getDate = (ts,timeZone) => {
    // return dayjs.unix(ts||null).tz(timeZone||getTimeZone()).format();
    return getDateFormat(ts,'DD/MM/YYYY',timeZone);
};
function getFirstDayInMonth(ts,timeZone) {
    if(ts)
        return dayjs.unix(ts).tz(timeZone||getTimeZone()).startOf('month').hour(12).unix();
    return dayjs().tz(timeZone||getTimeZone()).startOf('month').unix();
}
function getLastDayInMonth(ts,timeZone) {
    if(ts)
        return dayjs.unix(ts).tz(timeZone||getTimeZone()).endOf('month').unix();
    return dayjs().tz(timeZone||getTimeZone()).endOf('month').unix();
}
const getLastMinuteTs = (timeZone) => {
    const time = dayjs().tz(timeZone||getTimeZone()).unix();
    return time - (time % 60);
};
const getFollowingMonth=(ts,timeZone) =>{
    if(!ts)
        return;
    return dayjs.unix(ts).tz(timeZone||getTimeZone()).add(1,'month').unix();
};
const getNextDays=(ts,days,timeZone) =>{
    if(ts)  
        return dayjs.unix(ts).tz(timeZone||getTimeZone()).add(days,'day');
    return dayjs().tz(timeZone||getTimeZone()).add(days,'day').unix();
};
const getPrevMonth=(ts,timeZone) =>{
    return dayjs().tz(timeZone||getTimeZone()).subtract(1, 'month').unix();
};
const subtractTs = (ts, howMuch, unit,timeZone) => {
    return dayjs.unix(ts).tz(timeZone||getTimeZone()).subtract(howMuch, unit).unix();
};
const getMonthDifference=(startDate, endDate) =>{
    if(!startDate || !endDate)
        return 0;
    const date1 = dayjs.unix(startDate);
    const date2 = dayjs.unix(endDate);
    return date2.diff(date1, 'month')+1;
};
const getDaysDifference=(startDate, endDate) =>{
    if(!startDate || !endDate)
        return 0;
    const date1 = dayjs.unix(startDate);
    const date2 = dayjs.unix(endDate);
    return date2.diff(date1, 'days');
};

const getDateFormat = (ts, format,timeZone) => {
    if(ts)
        return dayjs.unix(ts).tz(timeZone||getTimeZone()).format(format);
    return dayjs().tz(timeZone||getTimeZone()).format(format);

};

const getMinutesDifference = (start, end) => {
    if(!start || !end)
        return 0;
    const date1 = dayjs.unix(start);
    const date2 = dayjs.unix(end);
    return date2.diff(date1, 'minute');
};

const getStartOfDay = (ts,timeZone) => {
    return dayjs.unix(ts).tz(timeZone||getTimeZone()).startOf('day').unix();
};

const getEndOfDay = (ts,timeZone) => {
    return dayjs.unix(ts).tz(timeZone||getTimeZone()).endOf('day').unix();
};

const tsToStartOfMonth = (ts,timeZone) => {
    return dayjs.unix(ts).tz(timeZone||getTimeZone()).startOf('month').unix();
};

const tsToEndOfMonth = (ts,timeZone) => {
    return dayjs.unix(ts).tz(timeZone||getTimeZone()).endOf('month').unix();
};

const tsGetDayOfWeek = (ts,timeZone) => {
    return dayjs.unix(ts).tz(timeZone||getTimeZone()).day() + 1;
};

const tsGetDayOfWeekHour = (ts,timeZone) => {
    return getDateFormat(ts,'d_HH:mm',timeZone);
};

const tsGetDateAndMonth=(ts=null,timeZone=null)=>{
    return getDateFormat(ts,'DD.MM',timeZone);
};
const getWeeksDifference=(startDate, endDate) =>{
    if(!startDate || !endDate)
        return 0;
    const date1 = dayjs.unix(startDate);
    const date2 = dayjs.unix(endDate);
    return date2.diff(date1, 'week');
};

const getMonthDifferenceDayJs=(startDate, endDate) =>{
    if(!startDate || !endDate)
        return 0;
    const date1 = dayjs.unix(startDate);
    const date2 = dayjs.unix(endDate);
    return date2.diff(date1, 'month');
};

const tsGetYear=(ts,timeZone)=>{
    if(ts)
        return dayjs.unix(ts).tz(timeZone||getTimeZone()).year();
    else
        return dayjs().tz(timeZone||getTimeZone()).year();
};
const getHourMinutes = (ts,timeZone) => {
    return getDateFormat(ts,'HH:mm',timeZone||getTimeZone());
};
const getHourMinutesDate = (date) => {
    const ts = getTs(date);
    return getHourMinutes(ts);
};
const isToday = (ts,timeZone) => {
    let isToday = require('dayjs/plugin/isToday');
    dayjs.extend(isToday);
    return dayjs.unix(ts).tz(timeZone||getTimeZone()).isToday();
};

const addMinute=(ts,minute,timeZone)=>dayjs.unix(ts).tz(timeZone||getTimeZone()).add(minute,'minute').unix();

const addHour=(ts,hour=1,timeZone)=>dayjs.unix(ts).tz(timeZone||getTimeZone()).add(hour,'hour').unix();

const addMonth=(ts,month=1)=> dayjs.unix(ts).add(month,'month').unix();

const subtractMonth=(ts,month=1,timeZone)=>dayjs.unix(ts).tz(timeZone||getTimeZone()).subtract(month,'month').unix();

const subtractHour=(ts,hour=1,timeZone)=>dayjs.unix(ts).tz(timeZone||getTimeZone()).subtract(hour,'hour').unix();

const getNow=(timeZone)=>dayjs().tz(timeZone||getTimeZone()).unix();

const combineDateTime=(dateTimestamp, timeTimestamp) =>{
    const dateObj = dayjs.unix(dateTimestamp);
    const timeObj = dayjs.unix(timeTimestamp);
    return dateObj.set('hour', timeObj.hour())
        .set('minute', timeObj.minute())
        .set('second', timeObj.second())
        .set('millisecond', timeObj.millisecond())
        .unix();
};

const  isSameDay=(ts1, ts2)=> {
    const date1 = dayjs.unix(ts1).format('DD/MM/YYYY');
    const date2 = dayjs.unix(ts2).format('DD/MM/YYYY');
    return date1 === date2;
};

export const getDuration=(start,end)=>{
    let diff = (end - start)/60;
    return Math.round(diff);
};
const getStartAndEndOfTimes = (time) => {
    dayjs.extend(quarterOfYear);
    return {
        startDate: dayjs().startOf(time).startOf('day').unix(),
        endDate: dayjs().endOf(time).endOf('day').unix()
    };
};

export {
    getTs,
    subtractTs,
    getStartOfDay,
    getEndOfDay,
    getDate,
    getDateFormat,
    tsToStartOfMonth,
    tsToEndOfMonth,
    getHourMinutes,
    tsGetDayOfWeek,
    tsGetDayOfWeekHour,
    isToday,
    getLastMinuteTs,
    getHourMinutesDate,
    tsGetDateAndMonth,
    getFirstDayInMonth,
    getLastDayInMonth,
    getFollowingMonth,
    getTime,
    getMonthDifference,
    getDaysDifference,
    tsGetYear,
    tsToDate,
    timeToDateTs,
    getWeeksDifference,
    getMonthDifferenceDayJs,
    getPrevMonth,timeToDate,getNextDays,
    addMinute,
    addHour,
    subtractHour,
    getNow,
    addMonth,
    subtractMonth,
    combineDateTime,
    getMinutesDifference,
    isSameDay,
    getStartAndEndOfTimes
};
