import * as css from './EventDay.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {XHR_LOADING_STATE} from '../../../../assets/constants/constants';
// import {ValidationsChart} from './components/ValidationsChart/ValidationsChart';
import Btn from '../../../../component/button/btn/Btn';
import {op, share} from '../../../../assets/symbols/symbols/Symbols.css';
import {androidIcon, iosIcon} from '../../../../assets/symbols/symbols/appsIcons';
import List from '../../../../component/list/List';
import {useHistory} from 'react-router-dom';
import {ANDROID_APP, IOS_APP} from '../../../../config/config';
import EventDayTemplate from './EventDayTemplate';
import useShow from '../../../../hooks/useShow';
import DownloadIcon from './components/icons/DownloadIcon';
import FullScreenIcon from './components/icons/FullScreenIcon';
import StopwatchIcon from './components/icons/StopwtchIcon';
import {TicketsSection} from './components/TicketsSection/TicketsSection';

export function EventDay() {
    const {t} = useTranslation('realtime');
    const showDownloadList = useShow();
    const showOpList = useShow();
    const isLoading = useSelector((s:any)=>s.realtime?.event?.getEventStatsXHR === XHR_LOADING_STATE);
    const history = useHistory();
    const event = useSelector((s:any)=>s.event);

    const downloadList = [
        {
            title: t('androidApp'),
            icon: androidIcon,
            onClick: () => window.open(ANDROID_APP, '_blank')
        },
        {
            title: t('iosApp'),
            icon: iosIcon,
            onClick: () => window.open(IOS_APP, '_blank')
        }
    ];
    const eventPath=event.type==='course'?'courses':'tickets';
    const opList = [
        {
            title: t('smsEnterContent'),
            onClick: () => history.push(`/e/${event.eid}/${eventPath}/branding/sms`)
        }
    ];

    if(isLoading) 
        return <EventDayTemplate />;
    
    return (
        <css.wrapper>
            <css.header>
                <css.title>{t('eventDay')}</css.title>
                <css.buttons>
                    <Btn className={'yellow'} onClick={showDownloadList.open} disabled={false}>
                        <css.icon>
                            <DownloadIcon />
                        </css.icon>
                        {t('downloadApp')}
                    </Btn>
                    <Btn className={'yellow'} onClick={undefined} disabled={true}>
                        <css.icon image={share} />
                        {t('share')}
                    </Btn>
                    <css.button className={'disabled'}>
                        <FullScreenIcon />
                    </css.button>
                    <css.button image={op} onClick={showOpList.open} />
                </css.buttons>
            </css.header>
            {!!showDownloadList.isOpen && (
                <List
                    list={downloadList}
                    showClass="show"
                    close={showDownloadList.close}
                    width="180px"
                    top={0}
                    left={'120px'}
                />
            )}
            {!!showOpList.isOpen && (
                <List
                    list={opList}
                    showClass="show"
                    close={showOpList.close}
                    width="180px"
                    top={0}
                    left={0}
                />
            )}
            <css.content>
                <css.autoUpdate>
                    <StopwatchIcon />
                    {t('autoUpdate')}
                </css.autoUpdate>
                <css.validationsChartSection>
                    {/* <ValidationsChart 
                        validatedTickets={100}
                        notValidatedTickets={50}
                    /> */}
                </css.validationsChartSection>
                <css.update>
                    <StopwatchIcon />
                    {t('updateIn', {minutes: '08:00'})} <css.link>{t('willUpdateNow')}</css.link>
                </css.update>
                <css.terminals>
                    {/* <TerminalList /> */}
                </css.terminals>
                <TicketsSection />
            </css.content>
        </css.wrapper>
    );
}
