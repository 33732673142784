import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import useEffectOnUpdate from '../../hooks/useEffectOnUpdate';
import {eventTypes} from './eventRealtime/eventTypes';

export const windowsSettings = {
    eventDay:{minSize: 12, defaultSize: 12},
    cash:{minSize: 4, defaultSize: 4},
    soldTickets:{minSize: 4, defaultSize: 4},
    transactions:{minSize: 4, defaultSize: 4},
    realtimeData:{minSize: 12, defaultSize: 12},
    tickets:{minSize: 8, defaultSize: 8},
    weather:{minSize: 4, defaultSize: 4},
    message:{minSize: 4, defaultSize: 4}
};
const MAX_COLUMNS = 12;
    
export default function useRealtimeGrid(setGrid=null){
    const type = useSelector((s:any) => s.event.type);
    
    const defaultGridDef = {eventDay:12, cash:4, soldTickets:4, transactions:4, realtimeData:12, tickets: 8, weather:4, message:4};
    const [windowsObj, setWindowsObj] = useState(defaultGridDef);
    const windowsList = eventTypes[type]?.windowsList || eventTypes.default.windowsList;

    const [updateWindowsList, setUpdateWindowsList] = useState(windowsList);
    // const gridAreas = useSelector((s:any) => s.realtime.user.realtimeDesign?.gridAreas || '"eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay cash cash cash cash soldTickets soldTickets soldTickets soldTickets transactions transactions transactions transactions" "realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData" "tickets tickets tickets tickets tickets tickets tickets tickets weather weather weather weather" "message message message message message message message message message message message message"');
    const gridAreas = '"eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay eventDay" "cash cash cash cash soldTickets soldTickets soldTickets soldTickets transactions transactions transactions transactions" "realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData" "tickets tickets tickets tickets tickets tickets tickets tickets weather weather weather weather" "message message message message message message message message message message message message"';

    useEffectOnUpdate(()=> {
        if(gridAreas){
            const converted = convertGridStringToObject(gridAreas) as any;
            const afterNormalizeGrid = redistributeGrid(converted,'');
            if(JSON.stringify(afterNormalizeGrid)!==JSON.stringify(windowsObj)){
                setWindowsObj(afterNormalizeGrid as any);
            }
        }
    },[gridAreas]);
    useEffect(()=> {
        setImmediate(()=>{
            if(gridAreas){
                const afterNormalizeGrid = redistributeGrid(windowsObj,'');
                const newGrid = convertGridObjectToString(afterNormalizeGrid);
                if(gridAreas !==newGrid && setGrid) {                
                    // setGrid(newGrid);
                }
            }
        });
    },[windowsObj]);

    const showHideWin = (win, show) => {
        updateWindowSize(win, show?windowsSettings[win].minSize:0, true);
    };
    const updateWindowSize = (win, newSize, fromShowHide = false) => {
        updateGrid(win, newSize, fromShowHide);
    };
    const convertGridStringToObject = (gridString ='') => {
        const gridObject = {};
        windowsList.forEach(win=>{
            const key = gridString?.split(' ')?.filter(area=>area.includes(win))?.length || 0;
            gridObject[win] = key;
        });
        return gridObject;
    };
    const getShowWindow = (win:string) =>{
        return gridAreas?.includes(win);
    };
    const updateGridArea = (gridArea ='', newSize=0, fromShowHide) => {
        const tempObj = JSON.parse(JSON.stringify(windowsObj));
        const {defaultSize, minSize} = windowsSettings[gridArea];
        tempObj[gridArea] = (fromShowHide && newSize === 0) ? 0 : newSize ? Math.max(newSize, minSize) : defaultSize;
        return tempObj;
            
    };
    const updateGrid = (gridArea ='', newSize=0, fromShowHide) => {
        const ironField = gridArea;
        const tempObj = updateGridArea(gridArea, newSize, fromShowHide);
        const afterNormalizeGrid = redistributeGrid(tempObj, ironField);
        setWindowsObj(afterNormalizeGrid as any);
    };
    const reorderGrid = (order: string[]) => {
        const reOrderedGrid = {};
        order.forEach(element => {
            reOrderedGrid[element] = windowsObj[element];
        });
        setUpdateWindowsList(order);
        setWindowsObj(reOrderedGrid as any);
    };

    function convertGridObjectToString(gridObject) {
        let rowWidth = 0;
        let stringLine='';
        let stringGrid = '';
        Object.entries(gridObject).forEach(([area, size]:[string, number], index) => {
            rowWidth += size;
            if(rowWidth <= MAX_COLUMNS) {
                stringLine = `${stringLine} ${Array(size).fill(area).join(' ')}`;
            } else {
                stringGrid = `${stringGrid} "${stringLine}"`;
                stringLine = Array(size).fill(area).join(' ');
                rowWidth = size;
            }
            if(index === Object.keys(gridObject).length - 1) {
                stringGrid = `${stringGrid} "${stringLine}"`;
            }
        });
        return stringGrid;
    }
    function getNextItemIndex(gridArray, currentIndex) {
        let nextIndex = currentIndex + 1;
        let nextItemIndex = null;
        while(nextIndex < gridArray.length) {
            if(gridArray[nextIndex][1]){
                nextItemIndex = nextIndex;
                nextIndex = gridArray.length;
            } else {
                nextIndex++;
            }
        }
        return nextItemIndex;
    }
    function redistributeGrid(grid, ironField) {
        const fixedGrid = {};
        let currentRowWidth = 0;
        const gridArray:[string, number][] = Object.entries(grid);
        gridArray.forEach(([area, size]:[string, number], index)=> {
            const minSize = windowsSettings[area].minSize;
            const nextItemIndex = getNextItemIndex(gridArray, index);
            if(gridArray[nextItemIndex] || area === ironField || size === 0){
            
                if(area === ironField || size === 0) {
                    fixedGrid[area] = size;
                    currentRowWidth += size;

                } else if((currentRowWidth + gridArray[nextItemIndex][1] >=MAX_COLUMNS) ){
                    const newSize = Math.max(MAX_COLUMNS - currentRowWidth, minSize);
                    fixedGrid[area] = newSize;
                    currentRowWidth += newSize;

                } else if((currentRowWidth + Math.max(size, minSize) + gridArray[nextItemIndex][1] >MAX_COLUMNS) ){
                    const newSize = Math.max(MAX_COLUMNS - currentRowWidth, minSize);
                    fixedGrid[area] = newSize;
                    currentRowWidth += newSize;

                } else {
                    const newSize = Math.max(size, minSize);
                    fixedGrid[area] = newSize;
                    currentRowWidth += newSize;
                }
            } else {
                fixedGrid[area] = MAX_COLUMNS - currentRowWidth;
            }
            if(currentRowWidth >= MAX_COLUMNS) {
                currentRowWidth = 0;
            }
        });
        return fixedGrid;
    }

    return {updateWindowSize, windowsList: updateWindowsList, getShowWindow, showHideWin, reorderGrid};
}