import styled from 'styled-components';
import {BLACK, dFlex, mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {wrapper} from '../stepRight/StepRight.css';
import {wrapper as wrapperLeft} from '../StepLeft/StepLeft.css';
import {iframe} from '../../../../layout/myIframe/MyIframe.css';
import {checkbox} from '../../../../component/checkbox/Checkbox.css';
import {btn} from '../../../../component/button/btn/Btn.css';
import {text, wrapCheckbox} from '../../../../component/checkboxFormik/CheckboxFormik.css';

export const wrap=styled.div`
    ${dFlex};
    flex-direction: column;
    width: 100%;
    padding-block-end:15px;
    input{
         color: ${BLACK};
    }
    ${wrapper}{
        width: 100%;
    }
    ${btn}{
        border-color:${BLACK} ;
        &:hover{
            color:${BLACK};
            border-color:${BLACK};
        }
    }
    ${wrapperLeft}{
        width: 100%;
    }
`;
export const wrapContent=styled.div`
    height:350px;
    position: relative;
    ${iframe}{
        height: calc(100% - 40px);
    }
    ${mobileMedia}{
        height:250px;
    }
`;
export const wrapSvg=styled.div`
    display:flex;
    padding-block:15px;
    ${mobileMedia}{
    display:grid;
    grid-template-columns: repeat(3 , 180px);
    gap: 10px;
        ${scrollbar};
        padding-inline:10px;
    }
`;
export const wrapButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    ${mobileMedia}{
        display:unset;
    }
    ${text}{
        font-weight: 600;
        font-size: 12px;
        width:max-content;
        margin-inline-start: 5px;
    }
    ${wrapCheckbox}{
        width:unset;
        input[type=checkbox]:before {
         content: unset;
        }
    }
    ${checkbox}{
        &.selected{
            &::before{
                background:black;
                right: calc(50% - 3px);
                top: calc(50% - 3px);
            }
        }
    }
`;

