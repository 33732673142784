import NewEventForm from '../../components/newEventForm/NewEventForm';
import * as Yup from 'yup';
import {duplicateInitialValues, newEventInitialValues,newEventSteps, newEventValidationSchema} from '../../globalSteps';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {getBudgetItems} from '../../../../redux/account/accountActions';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {eventFinishDuplication, getHalls} from '../../../../redux/event/eventActions';
import {openDesignPop} from '../../../../layout/Layout';
import setGlobalState from '../../../../redux/setGlobalState';
import {generateSVG} from '../../../../functions/generateSVG';

function NewAreaEvent() {
    const event=useSelector(s=>s.event);
    const budgetItems = useSelector(s=>s.account.budgetItems);
    const {t} = useTranslation(['global', 'newEvent', 'errors', 'layout']);

    const {duplicateSourceEid, isOpenLocaleStep} = useSelector(s=>s.ui.event);
    const history = useHistory();
    const eventSteps = [
        newEventSteps.title,
        newEventSteps.category(t),
        newEventSteps.location, 
        ...(event?.ready !== 5 ? [newEventSteps.map] : []),
        newEventSteps.date,
        ...(isOpenLocaleStep ? [newEventSteps.locale] : []),
        newEventSteps.artists,
        newEventSteps.description,
    ];
   
    const steps = [
        ...eventSteps,
        ...(budgetItems.length ? [newEventSteps.budgetIndex] : []),
        ...(duplicateSourceEid? [newEventSteps.duplicate(t)]: []),
    ];
        
    useEffect(()=>{
        getBudgetItems();
        {event.halls || getHalls({search: '', limit: 10, offset: 0}).then((res)=>{
            const updatedRes = res?.slice(0, 3).map(item => ({ 
                ...item, 
                svg: generateSVG(item.design) 
            }));
            setGlobalState('event', {...event,halls:res, maps:updatedRes});
        });}
    },[]);

    const duplicateSubmit = (form) => {
        const values = form.getFieldMeta('duplicate').value;
        eventFinishDuplication(event.eid,duplicateSourceEid,values).then(
            history.push(`/e/${event.eid}/${'tickets'}`),
            openDesignPop()
        );
    };
    
    const initialValues = {
        ...newEventInitialValues(event),
        map:{eid:0, title:'',approveMap:false,sendMap:null,message:'',action:''},
        ...(duplicateSourceEid ? {duplicate: duplicateInitialValues(event?.type)} : {})
    };
    const submitBtnProps = {
        onSubmit: duplicateSubmit,
        children: t('newEvent:saveDuplicate'),
    };
    return (
        <NewEventForm
            initialValues={initialValues}
            validationSchema={Yup.object().shape(newEventValidationSchema(t))}
            steps={steps}
            submitBtnProps={duplicateSourceEid ? submitBtnProps: undefined}
        />
    );
}

export default NewAreaEvent;
