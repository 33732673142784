import styled from 'styled-components';
import {BGImageCenter, BLACK, BUTTON_HEIGHT, DEFAULT_COLOR, dFlex, dFlexSpaceBetween, SHADOW_COLOR} from '../../../../globalVariables/GlobalVariables';
import {drop} from '../../../../component/dropzone/Dropzone.css';
import {btn} from '../../../../component/button/btn/Btn.css';
import {arrowDark, exit} from '../../../../assets/symbols/symbols/Symbols.css';
import {charNum} from '../../../../component/input/textareaInput/TextareaInput.css';

export const wrap = styled.div`
  ${dFlex};
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  height: 100px;
  border-radius: 15px 15px 0 0;
  background-color: ${SHADOW_COLOR};
  border-bottom: 2px solid;
  margin-block-end: 10px;
  textArea{
    border: none;
    font-size: 12px;
    font-weight: bold;
    &:hover{
      color: ${BLACK};
    }
  }
  ${charNum}{
    display:none ;
}
`;

export const title = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const explainButtons=styled.div<{ $isUpload?: boolean; }>`
    ${dFlexSpaceBetween}
    p{
      width: 60px;
    }
    ${drop}{
    position:relative;
     white-space:nowrap;
    padding-inline-start: 40px;
    padding-inline-end: 10px;
    border: 1px solid;
    border-radius: 25px;
    height: ${BUTTON_HEIGHT}px;
    line-height: ${BUTTON_HEIGHT}px;
    text-align: center;
    width: max-content;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    background-color: ${p => p.$isUpload && `${DEFAULT_COLOR}`};
    p{
      width: 100%;
    }
       &::before{
    content: '';
    height: 15px;
    width: 15px;
    ${BGImageCenter(exit,'contain')}
    transform: rotate(-90deg);
    position: absolute;
    inset-inline-start: 15px;
    inset-block-start: 7px;
  }
    }
    ${btn}{
      min-width:95px;
    }
`;
export const back = styled.div`
    cursor: pointer;
    margin-inline-start: 5px;
    width: 18px;
    height: 18px;
    ${BGImageCenter(arrowDark,'18px')}
`;
