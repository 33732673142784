import * as css from './BasicInput.css';
import {Field, useField} from 'formik';
import {useRequiredFields} from '../../../hooks/useValidationSchemaContext';
import TooltipAbsolute from '../../tooltip/tooltipAbsolute/TooltipAbsolute';

function BasicInput(props) {
    const {name,text = '',icon = '',description = '',className,events = {},types = {fieldType: 'input', inputType: 'text'},forwardRef, dataCy=''} = props;
    const [field, meta, helpers] = useField(name);
    const required = useRequiredFields(name);

    const onBlur = () => {
        const {value} = meta;
        if(!meta.touched || meta.error) return;
        if(events.onBlur) events.onBlur(name, value);
    };

    const onChange = (e) => {
        if(!meta.touched) helpers.setTouched(true);
        field.onChange(e);
    };
    const hasIcon=icon!==false;
    return (
        <css.container className={className + ' ' + (meta.touched && (meta.error ? 'invalid' : meta.value && 'valid'))}
            data-cy={`${dataCy || name.substring(name.indexOf('.')+1)}Validation`}>
            <css.wrapInput
                data-cy={`${dataCy || name.substring(name.indexOf('.')+1)}Input`} $dataText={text} $dataDesc={description} $types={types}
                className={(!field.value && field.value !== 0 && ' defaultInput') + (meta.touched && meta.error && ' invalid') +(hasIcon&&'hasIcon')}>
                {hasIcon&&<css.icon
                    className={(meta.touched && meta.value && field.value !== 0 && (!meta.error ? ' valid ' : ' invalid ')) + ' ' + className}
                    icon={icon}/>}
                <Field
                    innerRef={forwardRef}
                    as={types.fieldType}
                    type={types.inputType}
                    name={name}
                    onChange={onChange}
                    {...events}
                    onBlur={onBlur}
                    placeholder=""

                />
                {meta.touched && meta.error && (<TooltipAbsolute show={true} text={meta.error} /> )}
                <css.textWrap className={hasIcon&&'hasIcon'}>
                    <css.titleWrap className={!meta.value && field.value !== 0 && ' empty'} >
                        <css.title>{text}</css.title>
                        {required && <css.required className={'required'} />}&nbsp;&nbsp;
                    </css.titleWrap>
                    <css.description>{description}</css.description>
                </css.textWrap>
            </css.wrapInput>
        </css.container>
    );
}
export default BasicInput;
