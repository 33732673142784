import styled from 'styled-components';
import {
    mobileMedia,
    scrollbar,
    BGImageCenter,
    dFlexCenter, ellipsisLines, desktopMedia
} from '../../../globalVariables/GlobalVariables';
import {ProfileMenuStyledCtr} from '../../../layout/topMenu/layoutUserProfile/layoutUserProfileMenu/LayoutUserProfileMenu.css';
import fonts from '../fonts';
import {firstPage} from '../../../assets/symbols/images/Images.css';
import {buttonNo} from '../../../component/button/btnAdd/BtnAdd.css';
import {btnPlus, plusIcon} from '../../../component/button/btnPlus/BtnPlus.css';
import zIndex from '../../../globalVariables/zIndex';
import {getEventDetailsBackground, windowBase} from '../style.css';
import {getColor} from '../eventRealtime/EventRealtime.css';

export const WINDOW_HEIGHT = '300px';

export const wrapper = styled.div<{ colors?: string[]; }>`
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, #64949C 0%, #32ACD4 50%, #FFFFFF 100%);
        opacity: ${p=>p.colors && p.colors.length > 0 ? 0 : 1};
        transition: opacity 400ms;
    }
    transition: all 0.5s;
    height: 100%;
    ${scrollbar};
    color: ${p=>getColor(p.theme.isDarkMode)};
    ${ProfileMenuStyledCtr}{
        &.show{
            top: 0;         
        }
    }
 `;

export const baseGrid = styled.div`
 width: 80%;
 margin: 0 auto 20px auto;
 display: grid;
 gap: 20px;
 position: relative;          
 ${mobileMedia}{
     gap: 12px;
     width: 96%;
     margin: 10px auto;
}
`;
export const gridTop = styled(baseGrid)`
    grid-template-areas:"profile details";
    grid-template-columns: 200px calc(100% - 220px);
    ${mobileMedia}{
        grid-template-areas:"profile"
                            "details";
        grid-template-columns: 100%;

   }
`;
export const gridContainer = styled(baseGrid)<{ rolePermission? : boolean; }>`
    ${p=>p.rolePermission ? `grid-template-areas:"eventSection eventSection windowsReports";
    grid-template-columns: 1fr 1fr 1fr;`: `grid-template-areas:"eventSection eventSection";
    grid-template-columns: 100%;`} ;
     ${mobileMedia}{
        grid-template-areas:"eventSection"
                             "windowsReports";
        grid-template-columns: 100%;

   }
`;

export const accountDetails = styled.div`
    grid-area: details;
    display: grid;
    grid-template-areas:"title menu"
                        "colors ."
                        "team accountState";
    position: relative;
    ${getEventDetailsBackground}
`;

export const top = styled.div`
    ${dFlexCenter};
    justify-content: space-between;
    height: 100px;
    width: 96%;
    margin: 0 auto;

`;
export const title = styled.div`
    grid-area: title;
    ${fonts.title};
    color: ${p=>getColor(p.theme.isDarkMode)};
    span{
        height: 22px;
        margin-top: -22px;
        font-weight: 400;
        ${fonts.text};
        display: block;
        color: ${p=>getColor(p.theme.isDarkMode)};

    }

    ${mobileMedia}{
        line-height: 35px;
        z-index: ${zIndex.baseRaised};
        ${ellipsisLines(2)};
        width: calc(100% - 100px);
    }
`;
export const menuScroll = styled.div` 
    width: max-content;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
`;
export const menu = styled.div`
    grid-area: menu;
    ${scrollbar};
    max-width:100%;
    ${desktopMedia}{
        display: flex;
        justify-content: flex-end;
    }
    
`;

export const colors = styled.div`
    grid-area: colors;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
     ${mobileMedia}{
        display: none;
    }
 `;

export const color = styled.div`
    width: 12px;
    height: 13px;
    border-radius: 5px;
    background-color: ${p => p.color};
 `;
export const team = styled.div`
    grid-area: team;
`;
export const accountState = styled.div`
    grid-area: accountState;
    display: flex;
    justify-content: flex-end;
    ${mobileMedia}{
        display: none;
    }
`;
interface accountVerificationProps {
    icon?: string;
    isVerified?: boolean;
}
export const accountVerification = styled.div<accountVerificationProps>`
    ${p=>BGImageCenter(p.icon,'90px 90px')};
    cursor: pointer;
    width: 100px;
    padding-top: 100px;
    text-align: center;
    ${mobileMedia}{
        background-size:contain;
        height: 100%;
        z-index: ${zIndex.baseRaised};
        width: 75px;
    }
`;

export const eventSection = styled.div`
    grid-area: eventSection;
    ${windowBase};
    //position: relative;
    padding: 20px;
    height: ${WINDOW_HEIGHT};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${buttonNo}{
        padding-block: 3px;
        padding-inline-start: 3px;
    }
    ${mobileMedia}{
        padding: 12px;
        height: 190px;
        ${btnPlus}{
            width: 30px;
            height: 30px;
            
        }
        ${plusIcon}{
            width: 29px;
            height: 29px;
    
        }
    }
    

`;

export const text = styled.div`
    ${fonts.windowTitle};
    font-size: 18px;
    text-align: center;
    color: ${p=>getColor(p.theme.isDarkMode)};
`;

export const image = styled.div`
  ${BGImageCenter(firstPage,'contain')};
  height: ${WINDOW_HEIGHT};
  width: 600px;
  max-width: 80%;
  margin: 0 auto;
  max-height: calc(100% - 100px);
  ${mobileMedia}{
    height: 77px;
    max-height: unset;
  }
`;
